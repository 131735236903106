import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";
import SingleCol from "components/faqs/SingleCol";
import { LogoLink } from "components/headers/light.js";
import logo from "images/logo.svg";
import { Link as ScrollLink } from "react-scroll";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {  Content2Xl, ContentWithVerticalPadding } from "components/misc/Layouts";

const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;

const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
const PrimaryNavLink = tw(
    NavLink
  )`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;

const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${props =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
      background: rgb(85,60,154);
      background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
      background: rgb(76,81,191);
      background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;

// Update BuyNowButton to be an anchor tag styled as a button
const BuyNowButton = styled.a`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline inline-block text-center`}
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;

export default ({
  subheading = "",
  heading = "SlanderScan Plan Options",
  description = "Choose the ideal plan to monitor your presence in Facebook group 'Are We Dating the Same Guy.' Whether you want a comprehensive one-time scan to check for any mentions or prefer continuous monitoring for ongoing updates, our tailored solutions fit your unique needs. Stay ahead with real-time alerts, detailed reports, and regular updates to effectively manage your online reputation. Our expert support team is always here to help, providing peace of mind and reliable monitoring. Select a plan that suits you best and take control of your online reputation today.",
  plans = null,
  primaryButtonText = "Buy Now"
}) => {
  const defaultPlans = [
    {
      name: "One-Time Scan",
      price: "$75",
      duration: "One-Time",
      mainFeature: "Comprehensive Scan",
      features: ["Detailed Report", "Identity Scan", "Screenshots", "Support"],
      link: "https://buy.stripe.com/eVa02Mb4D1HR7TOdQT"
    },
    {
      name: "Unlimited Scans & Continuous Monitoring (Better Deal)",
      price: "$79",
      duration: "Monthly",
      mainFeature: "Ongoing Online Reputation Protection",
      features: ["Unlimited Scans", "Continuous Monitoring", "Screenshots", "Priority Support"],
      featured: true,
      link: "https://buy.stripe.com/00gcPyb4D3PZ3DyaEI"
    },
    {
      name: "Unlimited Scans & Continuous Monitoring (Best Deal)",
      price: "$299",
      duration: "Annual",
      mainFeature: "Year-Round Comprehensive Monitoring",
      features: ["Unlimited Scans", "Continuous Monitoring", "Screenshots", "Dedicated Support"],
      link: "https://buy.stripe.com/aEUbLu1u30DNgqk28d"
    },
  ];

  if (!plans) plans = defaultPlans;

  const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
    `,
    css`
      background: rgb(56, 178, 172);
      background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
    `
  ];

  return (
    <AnimationRevealPage disabled>
    <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
      <Content2Xl>
    <Container>
        <NavRow>
            <LogoLink href="/">
              <img src={logo} alt="" />
              SlanderScan
            </LogoLink>
            <div tw="flex flex-wrap justify-center lg:justify-end items-center -mr-12">
              <a
                href="/"
                css={tw`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`}>
                Home
              </a>
              <ScrollLink                 
                to="faqs"
                smooth={true}
                duration={500}
                css={tw`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`}>
                FAQ/Contact Us
              </ScrollLink>
              {/* <NavLink target="_blank" href="https://owaiskhan.me">
                Who Am I ?
              </NavLink>
              <NavLink target="_blank" href="https://twitter.com/owaiswiz">
                Twitter
              </NavLink>
              <NavLink target="_blank" href="mailto:owaiswiz@gmail.com">
                Hire Me!
              </NavLink> */}
              <div tw="md:hidden flex-100 h-0"></div>
              <ScrollLink to="pricingPlans"
                smooth={true}
                duration={500}
                css={tw`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 mr-12 text-gray-700 no-underline hover:text-gray-700`}>
              {/* <PrimaryNavLink as="span">
              Begin your Scan
              </PrimaryNavLink> */}
                
              </ScrollLink>
            </div>
            
          </NavRow>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              {!plan.featured && <div className="planHighlight" css={highlightGradientsCss[index % highlightGradientsCss.length]} />}
              <PlanHeader>
                <span className="name">{plan.name}</span>
                <span className="price">{plan.price}</span>
                <span className="duration">{plan.duration}</span>
              </PlanHeader>
              <PlanFeatures>
                <span className="feature mainFeature">{plan.mainFeature}</span>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton 
                  href={plan.link} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  onClick={() => console.log('Button clicked!')} 
                  css={!plan.featured && highlightGradientsCss[index]}
                >
                  {primaryButtonText}
                </BuyNowButton>
              </PlanAction>
            </Plan>
          ))}
          <DecoratorBlob />
        </PlansContainer>
        <div id="faqs">
            <SingleCol/>
        </div>
      </ContentWithPaddingXl>
    </Container>
    </Content2Xl>
    </Container>
    </AnimationRevealPage>
    
      
  );
};
